<template>
  <main>
    <CardBrowser v-if="cards && cards.length > 0" :cards="cards" :page="page" :page-count="pageCount" />
    <div class="no-content" v-if="cards && cards.length === 0">
      К сожалению, по этому тегу нет материалов :(
    </div>
    <LoadingBlock v-if="!cards"/>
  </main>
</template>

<script>
import LoadingBlock from '@/components/Shared/LoadingBlock'
import CardBrowser from '@/components/Shared/CardBrowser'

export default {
  name: 'TagCardList',
  components: { CardBrowser, LoadingBlock },
  props: [
    'slug'
  ],

  data() {
    return {
      cards: null,
      cardAmount: 0,

      cardsPerPage: 18,
    }
  },

  mounted() {
    this.getContentForTag()
  },

  watch: {
    $route: 'updateRoute',
  },

  methods: {
    getContentForTag() {
      this.cards = null
      this.cardAmount = 0

      this.$root.makeAPIRequest('tags', 'get_content', {
        slug: this.slug,
        cardsPerPage: this.cardsPerPage,
        offset: this.offset,
      })
        .then(response => {
          if(response.data.error) {
            console.error(`Ошибка загрузки материалов по тегу: ${response.data.message}`)
            switch(response.data.handler) {
              case 'not-found':
                this.$router.replace({ name: 'not-found' })
            }
            return
          }

          this.cardAmount = response.data.result.amount
          this.cards = response.data.result.cards
          this.$root.setTitle(`#${this.$route.params.slug}`)
        })
    },

    updateRoute() {
      this.getContentForTag()
    },
  },

  computed: {
    page() {
      if(!this.$route.query.page || Array.isArray(this.$route.query.page)) {
        return 1
      }

      // noinspection JSCheckFunctionSignatures
      const pageNum = parseInt(this.$route.query.page)
      if(isNaN(pageNum)) {
        return 1
      }

      if(pageNum < 1) {
        return 1
      }

      return pageNum
    },

    pageCount() {
      return Math.ceil(this.cardAmount / this.cardsPerPage)
    },

    offset() {
      return (this.page - 1) * this.cardsPerPage
    },
  },
}
</script>

<style lang="less" scoped>
  @import (reference) '../shared.less';

  main {
    width: var(--content-width);
    align-self: center;
  }

  .no-content {
    font-size: @font-size-header;
    font-family: @font-set-header;
    text-align: center;
    opacity: 0.5;
  }
</style>
