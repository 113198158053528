<template>
  <main>
    <CardBrowser v-if="cards && cardAmount <= 500" :cards="cards" :page="page" :page-count="pageCount" />
    <LoadingBlock v-if="cards === null && !tooShortQuery" />
    <div class="error" v-if="(cards && cards.length === 0) || tooShortQuery">
      <span v-if="cardAmount > 500">Слишком много результатов. Попробуйте уточнить запрос</span>
      <span v-if="tooShortQuery">Слишком короткий запрос. Попробуйте уточнить</span>
      <span v-if="!tooShortQuery && cardAmount === 0">По вашему запросу ничего не найдено</span>
    </div>
  </main>
</template>

<script>
import LoadingBlock from '@/components/Shared/LoadingBlock'
import CardBrowser from '@/components/Shared/CardBrowser'

export default {
  name: 'Search',
  components: { CardBrowser, LoadingBlock },

  data() {
    return {
      cards: null,
      cardAmount: 0,
      tooShortQuery: false,
      page: 1,
    }
  },

  created() {
    this.routeChanged()
  },

  watch: {
    $route: 'routeChanged',
  },

  computed: {
    cardsPerPage() {
      return 18
    },

    pageCount() {
      return Math.ceil(this.cardAmount / this.cardsPerPage)
    },

    query() {
      const query = this.$route.query.query
      if(!query) {
        return ''
      }
      return query
    },
  },

  methods: {
    routeChanged() {
      this.updateData()
    },

    updateData() {
      this.page = this.$route.query.page ? parseInt(this.$route.query.page) : 1
      if(this.page < 1) {
        this.page = 1
      }
      this.fetchCards()
    },

    fetchCards() {
      this.cards = null
      this.cardAmount = 0
      this.tooShortQuery = false

      this.$root.makeAPIRequest('search', 'get_content', {
        query: this.query,
        cardsPerPage: this.cardsPerPage,
        offset: (this.page - 1) * this.cardsPerPage,
      })
        .then(response => {
          if(response.data.error) {
            console.error(`Ошибка загрузки данных: ${response.data.message}`)
            if(response.data.handler && response.data.handler === 'too-short-query') {
              this.tooShortQuery = true
              return
            }
            alert('Ошибка загрузки данных с сервера. Пожалуйста, перезагрузите страницу.')
            return
          }

          this.cards = response.data.result.cards
          this.cardAmount = response.data.result.amount
          this.tooShortQuery = false
        })
    },
  },
}
</script>

<style lang="less" scoped>
  @import (reference) '../shared.less';

  main {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .card-block {
    width: var(--content-width);
  }

  .error {
    font-family: @font-set-header;
    font-size: @font-size-header;
    opacity: 0.5;
    text-align: center;
  }
</style>
