<template>
  <main>
    <div class="creator-utils" v-if="areCreatorUtilsShown">
      <router-link :to="{ name: `new-${type}` }" :class="['button', 'new-content', `type-${type}`]">+ {{creatorUtilsButtonText[type]}}</router-link>
    </div>
    <CardBrowser v-if="cards" :cards="cards" :page="page" :page-count="pageCount" />
    <LoadingBlock v-if="!cards" />
  </main>
</template>

<script>
import LoadingBlock from '@/components/Shared/LoadingBlock'
import CardBrowser from '@/components/Shared/CardBrowser'

// noinspection JSUnusedGlobalSymbols
export default {
  name: 'CardList',
  components: { CardBrowser, LoadingBlock },
  props: [
    'type',
  ],

  data() {
    return {
      cards: null,
      cardAmount: 0,
      page: 1,
      creatorUtilsButtonText: {
        article: 'Написать статью',
        event: 'Сообщить о событии',
        question: 'Задать вопрос',
        discussion: 'Начать дискуссию',
      }
    }
  },

  created() {
    this.routeChanged()
  },

  watch: {
    $route: 'routeChanged',
  },

  computed: {
    cardsPerPage() {
      if(this.type) {
        return 18
      }

      return 26
    },

    pageCount() {
      return Math.ceil(this.cardAmount / this.cardsPerPage)
    },

    areCreatorUtilsShown() {
      const types = ['article', 'event', 'question', 'discussion']
      return types.includes(this.type)
    },
  },

  methods: {
    routeChanged() {
      this.updateData()
    },

    updateData() {
      this.page = this.$route.query.page ? parseInt(this.$route.query.page) : 1
      if(this.page < 1) {
        this.page = 1
      }
      this.fetchCards()
    },

    fetchCards() {
      this.cards = null
      this.cardAmount = 0

      this.$root.makeAPIRequest('content', 'list', {
        cardsPerPage: this.cardsPerPage,
        offset: (this.page - 1) * this.cardsPerPage,
        type: this.type,
      })
        .then(response => {
          if(response.data.error) {
            console.error(`Ошибка загрузки данных: ${response.data.message}`)
            if(response.data.handler && response.data.handler === 'reset-to-first-page') {
              this.$router.replace({ query: {} })
              return
            }
            alert('Ошибка загрузки данных с сервера. Пожалуйста, перезагрузите страницу.')
            return
          }

          this.cards = response.data.result.cards
          this.cardAmount = response.data.result.amount
        })
    },
  },
}
</script>

<style lang="less" scoped>
  @import (reference) '../shared.less';

  main {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .creator-utils {
    margin-bottom: @s-margin;
    width: var(--content-width);

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    a {
      background-color: var(--type-color);
      color: @color-block-background;
      padding: 0.4em 0.6em;
    }
  }

  .card-block {
    width: var(--content-width);
  }

  .promo-banner {
    margin-top: 2em;
  }
</style>
